// extracted by mini-css-extract-plugin
export var storyRowWrapperCenter = "t_rY d_bC";
export var storyRowWrapper = "t_hv d_hv d_bJ";
export var storyLeftWrapper = "t_rZ d_by d_bN";
export var storyWrapperFull = "t_r0 d_cC";
export var storyWrapperFullLeft = "t_p9 d_cC d_by d_bN d_bC";
export var contentWrapper = "t_nS d_hw";
export var storyLeftWrapperCenter = "t_r1 d_hx d_by d_bC d_bN d_bH";
export var storyRightWrapperCenter = "t_r2 d_hC";
export var storyHeader = "t_r3 d_hB d_v d_cr";
export var storyHeaderCenter = "t_hz d_hz d_v d_cr d_dv d_bx";
export var storyParagraphCenter = "t_hy d_hy d_bx d_dv";
export var storyBtnWrapper = "t_r4 d_d1 d_d0 d_v d_by d_bF";
export var storyBtnWrapperCenter = "t_hG d_hG d_d0 d_v d_by d_bC";
export var imageWrapper = "t_qK d_ff d_Y";
export var imageWrapperFull = "t_r5 d_v d_G d_bd d_Y";