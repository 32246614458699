// extracted by mini-css-extract-plugin
export var articleWrapper = "p_nJ";
export var searchResult = "p_qQ p_nJ";
export var line = "p_fj d_fj d_cX";
export var articleTitle = "p_qR d_cp";
export var articleText = "p_nK d_cr";
export var authorImage = "p_lm d_lm d_b6 d_Y d_cs";
export var authorText = "p_n9 d_cg d_cs";
export var textCenter = "p_dv d_dv";
export var searchWrapper = "p_qS d_by d_bN d_dq d_ck d_Y";
export var searchInput = "p_qT d_cD d_v d_b3";
export var searchBtn = "p_qV d_b0 d_bB d_bN d_Z d_cC d_dz";
export var text = "p_pl d_cq";
export var elementWrapper = "p_mv d_ck";
export var select = "p_qW d_v d_b3";
export var articleImageWrapper = "p_n1 d_cr d_Y d_T";
export var articleImageWrapperIcon = "p_n2 d_cr d_Y d_T";
export var articleImageWrapperColumns = "p_qX d_cr d_fg d_Y";
export var contentPadding = "p_qY d_cR";
export var otherPadding = "p_qZ d_cL";
export var paginationWrapper = "p_q0";
export var pagination = "p_q1";
export var disabledPagination = "p_q2";
export var breakMe = "p_q3";
export var activePage = "p_q4";
export var next = "p_q5";
export var pages = "p_q6";
export var searchTitle = "p_q7 d_cq";
export var categoryTitle = "p_q8 d_cq d_Y";
export var searchText = "p_q9 d_cs";
export var bold = "p_rb";
export var field = "p_rc";
export var dateTag = "p_nT d_k d_cq d_dp";
export var icon1 = "p_nV d_cN";
export var icon2 = "p_nW d_cN d_cH";
export var tagBtn = "p_nX d_b0 d_cC d_dn d_dz";
export var linksWrapper = "p_pd d_cg d_dv";
export var header = "p_nL d_v d_Y";
export var headerImage = "p_rd d_bc d_Z d_7 d_6 d_3 d_8";
export var separator = "p_n8 d_cX";
export var resultImage = "p_rf";