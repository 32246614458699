// extracted by mini-css-extract-plugin
export var articleWrapper = "k_nJ";
export var articleText = "k_nK d_dt d_cr";
export var header = "k_nL d_Y d_v";
export var headerImageWrapper = "k_nM d_bc d_Z d_7 d_6 d_3 d_8 d_bj d_bQ";
export var headerGradient = "k_nN d_bc d_Z d_7 d_6 d_3 d_8";
export var headerGradientOverlay = "k_nP d_bc d_Z d_7 d_6 d_3 d_8";
export var headerContentWrapper = "k_nQ d_by d_bN d_bC d_bH d_bk d_Y";
export var headerContentWrapperAlt = "k_nR d_by d_bN d_bC d_bH d_bk d_Y";
export var contentWrapper = "k_nS d_v";
export var dateTag = "k_nT d_cd d_cq d_dp d_v";
export var icon1 = "k_nV d_cN";
export var icon2 = "k_nW d_cN d_cH";
export var tagBtn = "k_nX d_b0 d_cC d_dn d_dz";
export var headerText = "k_nY d_v d_cd d_cr d_bV";
export var center = "k_nZ d_dv";
export var videoIframeStyle = "k_n0 d_d4 d_v d_G d_bx d_b0 d_Q";
export var articleImageWrapper = "k_n1 d_cr d_Y";
export var articleImageWrapperIcon = "k_n2 d_cr d_Y";
export var articleRow = "k_n3 d_bC";
export var quoteWrapper = "k_gN d_bB d_bN d_cr";
export var quoteBar = "k_n4 d_G";
export var quoteText = "k_n5";
export var authorBox = "k_n6 d_v";
export var authorRow = "k_n7 d_bC d_bV d_cx";
export var separator = "k_n8 d_v";
export var line = "k_fj d_v d_fj d_cX d_cx";
export var authorImage = "k_lm d_b6 d_Y d_v d_cs";
export var authorText = "k_n9 d_cg d_cs";
export var masonryImageWrapper = "k_pb";
export var bottomSeparator = "k_pc d_v d_cx";
export var linksWrapper = "k_pd d_cg d_dt";
export var comments = "k_pf d_bC d_cx";
export var sharing = "k_pg d_by d_bC d_bH";
export var shareText = "k_ph d_v d_dv";
export var icon = "k_pj";
export var customRow = "k_pk d_bC d_Y";
export var text = "k_pl";
export var SubtitleSmall = "k_pm";
export var SubtitleNormal = "k_pn";
export var SubtitleLarge = "k_pp";